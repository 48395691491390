export enum LOCAL_STORAGE_KEY {
  DB_ID = "dbId",
}

export enum INTERNAL_ROUTE {
  LOGIN = "/login",
  START = "/",
  DASHBOARD = "/dashboard",
  STYLEGUIDE = "/styleguide",
  STYLEGUIDE_OVERLAY = "/styleguide-overlay",
  COMPANY_IDENTIFICATION = "/company-identification",
  COMPANY_IDENTIFICATION_RESULT = "/company-identification/result",
  COMPANY_IDENTIFICATION_BY_THIRD_PARTY = "/company-identification/by-third-party",
  COMPANY_IDENTIFICATION_INVITE_THIRD_PARTY = "/company-identification/third-party",
  COMPANY_IDENTIFICATION_INVITE_THIRD_PARTY_RESULT = "/company-identification/third-party/result",
  COMPANY_IDENTIFICATION_BY_THIRD_PARTY_CANCELLED = "/company-identification/by-third-party/cancelled",
  INVITE = "/invite",
  USERS = "/user-accesses",
  ONBOARDING = "/onboarding",
  WELCOME = "/welcome",
  FLEXIBILL = "/flexibill",
  PAYEE = "/payee",
  KULCSUSER = "/kulcs-user",
  COMPANIES = "/my-companies",
}

export enum COLOR {
  GREEN = "green",
  ORANGE = "orange",
  PRIMARY = "primary",
  WHITE = "white",
  BLACK = "black",
  LIGHT = "light",
}

export enum ROLE {
  "Administrator" = "Admin",
  "Basic" = "Felhasználó",
  "Sent" = "Függőben",
  "Accepted" = "Elfogadva",
}
