import { Links } from "src/utils/constants/Links";
import { ReactComponent as DatapointLogo } from "src/assets/images/adatpont-logo.svg";
import { ReactComponent as ByIcon } from "src/assets/images/by-logo.svg";
import { ReactComponent as KSLogo } from "src/assets/images/kulcs-soft-logo.svg";
import { A, Icon } from "src/components";
import { INTERNAL_ROUTE } from "src/utils/constants/Enums";
import { renderTestId } from "src/utils/HelperFunctions";

interface Props extends React.HTMLAttributes<HTMLDivElement> {}

const Footer: React.FC<Props> = () => {
  const links = Links.General;

  return (
    <footer className="self-stretch flex md:p-6 p-4  flex-col items-start gap-4 clear-both relative">
      <div className="flex md:px-4 py-0 px-1 md:py-4 flex-col md:flex-row items-center md:gap-4 gap-2 self-stretch rounded-2xl bg-contrast">
        <div className="flex h-[60px] items-center gap-9 w-[248px]">
          <DatapointLogo />
          <div className="flex items-center gap-2">
            <ByIcon /> <KSLogo />
          </div>
        </div>
        <div className="flex flex-col md:flex-row md:py-4 md:px-4 py-3 justify-end items-start md:items-center md:gap-6 gap-4 flex-[1_0_0] flex-wrap">
          <div className="flex items-center gap-2">
            <span className="text-primary text-center text-[14px] leading-[13.5px] font-500">
              <A
                {...renderTestId("footer-terms-link")}
                href={links.TermsAndConditions}
              >
                ÁSZF
              </A>
            </span>
            <Icon icon="external-link" size={16} className="text-primary" />
          </div>
          <div className="flex items-center gap-2">
            <span className="text-primary text-center text-[14px] leading-[13.5px] font-500">
              <A
                {...renderTestId("footer-privacy-link")}
                href={links.PrivacyPolicy}
              >
                Adatvédelmi tájékoztató
              </A>
            </span>
            <Icon icon="external-link" size={16} className="text-primary" />
          </div>
          <div className="flex items-center gap-2">
            <span className="text-primary text-center text-[14px] leading-[13.5px] font-500">
              <A
                {...renderTestId("footer-knowledge-center-link")}
                href={links.KnowledgeCenter}
              >
                Tudástár
              </A>
            </span>
            <Icon icon="external-link" size={16} className="text-primary" />
          </div>
          <div className="flex items-center gap-2">
            <span className="text-primary text-center text-[14px] leading-[13.5px] font-500">
              <A {...renderTestId("footer-help-link")} href={links.Help}>
                Kapcsolat
              </A>
            </span>
            <Icon icon="external-link" size={16} className="text-primary" />
          </div>
          <span className="text-primary text-center text-[14px] leading-[13.5px] font-500">
            <A
              {...renderTestId("footer-flexibill-link")}
              href={`${process.env.REACT_APP_SELF_BASE_URL}${INTERNAL_ROUTE.FLEXIBILL}`}
            >
              Faktorálás
            </A>
          </span>
          <span className="text-primary text-center text-[14px] leading-[13.5px] font-500">
            <A
              {...renderTestId("footer-payee-link")}
              href={`${process.env.REACT_APP_SELF_BASE_URL}${INTERNAL_ROUTE.PAYEE}`}
            >
              Követeléskezelés
            </A>
          </span>
          <span className="text-primary text-center text-[14px] leading-[13.5px] font-500">
            <A
              {...renderTestId("footer-kulcsuser-link")}
              href={`${process.env.REACT_APP_SELF_BASE_URL}${INTERNAL_ROUTE.KULCSUSER}`}
            >
              Kulcs-Felhasználó
            </A>
          </span>
        </div>
      </div>
      <div className="self-stretch font-500 text-center text-[13.5px] leading-[13.5px] text-primary/70">
        Copyright &copy; 2024 Kulcs-Soft. Minden jog fenntartva.
      </div>
    </footer>
  );
};
export default Footer;
