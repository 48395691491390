import React from "react";
import cn from "classnames";
import { Icon } from "src/components";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  number: number;
  status: "active" | "inactive" | "done";
  children: React.ReactNode;
}

const SurveyStep = ({ number, status, children, className }: Props) => {
  return (
    <div
      className={cn(
        "flex flex-wrap-nowrap items-center p-2 pr-4 gap-4 rounded-large w-fit",
        {
          "bg-primary/10": status === "active",
          "bg-transparent": status !== "active",
        },
        className
      )}
    >
      <div
        className={cn(
          "flex w-6 h-6 justify-center items-center rounded-large shadow-blue-10",
          {
            "bg-primary": status === "active",
            "bg-white": status !== "active",
          }
        )}
      >
        <div
          className={cn("text-sm font-medium select-none", {
            "text-white": status === "active",
            "text-primary": status !== "active",
          })}
        >
          {status === "done" ? <Icon icon="checkmark" size={16} /> : number}
        </div>
      </div>
      <div
        className={cn("text-base text-primary text-nowrap md:text-wrap", {
          "font-medium": status === "active",
          "font-normal": status !== "active",
        })}
      >
        {children}
      </div>
    </div>
  );
};

export default SurveyStep;
