import { useDispatch } from "react-redux";
import { AppDispatch } from "src/store/store";
import { setModalClose, useModalData } from "src/store/slices/modalSlice";
import { Overlay, P, Button } from "src/components";
import { ReactComponent as BubbleCheckMark } from "src/assets/icons/bubble-checkmark.svg";
import { useCompany } from "src/store/slices/companySlice";
import { renderTestId } from "src/utils/HelperFunctions";
import { useToast } from "src/components/_ui/toast/use-toast";
import {
  DataPointApplicationFeaturesUsersCommandsUpdateUserCommand,
  useGetUsersQuery,
  useUpdateUserMutation,
} from "src/store/serviceApi";

export interface Props extends React.HTMLAttributes<HTMLDivElement> {}

const MODAL_ID = "grant-admin-role-modal";

const adminPrivileges = [
  "Cégenként új felhasználók meghívása",
  "Másoknak admin jog megadása",
  "Felhasználók eltávolítása",
  "Cégek felvétele és törlése",
  "Faktorálás igénylése (Flexibill)",
  "Ügyvédi és jogi megoldások kezdeményezése (Payee)",
] as const;

const GrantAdminRoleModal: React.FC<Props> = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { toast } = useToast();
  const modalData = useModalData();
  const { company } = useCompany();

  useGetUsersQuery(
    {
      companyId: company?.id || "",
    },
    { refetchOnMountOrArgChange: true }
  );

  const [updateUser] = useUpdateUserMutation();

  const handleSubmit = async () => {
    await updateUser({
      companyId: company?.id ?? "",
      id: modalData?.id,
      body: modalData as DataPointApplicationFeaturesUsersCommandsUpdateUserCommand,
    })
      .then((res) => {
        if (!(res as any).data) {
          toast({
            title: "Sikeres admin jogosultség megadása",
            variant: "success",
          });
          closeModal();
        } else
          toast({
            title: "Az admin jogosultság megadása sikertelen",
            variant: "destructive",
          });
      })
      .catch((error) => {
        console.log("GRANT ADMIN ROLE MODAL UPDATE USER ERROR", error);
        toast({
          title: "Az admin jogosultság megadása sikertelen",
          variant: "destructive",
        });
      });
  };

  const closeModal = () => dispatch(setModalClose(MODAL_ID));

  return (
    <Overlay
      {...renderTestId(MODAL_ID)}
      id={MODAL_ID}
      className="md:w-[640px]"
      title="Admin jogosultság megadása"
      variant="Custom"
      size="sm"
    >
      <div className="flex !content-center gap-6 flex-col items-center flex-wrap">
        <div className="flex justify-start items-start gap-2 self-stretch">
          <P className="!font-700">{`${modalData?.familyName ?? ""} ${
            modalData?.givenName ?? ""
          }`}</P>
          <P className="opacity-60">részére</P>
        </div>
        <div className="flex p-4 flex-col justify-start items-start gap-4 self-stretch bg-[#007aff08] rounded-3xl">
          <P className="text-primary !font-500">Admin kiváltságok</P>
          <div className="flex self-w-full flex-col items-center gap-4">
            {adminPrivileges.map((privilege, i) => (
              <div className="flex self-stretch flex-col gap-4" key={i}>
                <div className="flex gap-3 self-stretch">
                  <BubbleCheckMark />
                  <P className="opacity-70">{privilege}</P>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="flex justify-start items-start gap-2 self-stretch text-left">
          <P>
            Biztos vagy benne, hogy admin jogosulsággal szeretnéd felruházni ezt
            a felhasználót?
          </P>
        </div>

        <div className="flex content-center md:flex-nowrap flex-wrap-reverse justify-center items-center w-full md:gap-6 gap-3 self-stretch text-center">
          <Button
            {...renderTestId("cancel-button")}
            onClick={closeModal}
            variant="Secondary"
            className="w-full"
          >
            <P className="text-primary !font-500 text-base">Mégse</P>
          </Button>

          <Button
            {...renderTestId("permisson-button")}
            variant="Primary"
            onClick={handleSubmit}
            className="w-full"
          >
            <P className="text-white !font-500 text-base">
              Jogosultság megadása
            </P>
          </Button>
        </div>
      </div>
    </Overlay>
  );
};

export default GrantAdminRoleModal;
