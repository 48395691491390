import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { RootState } from "../store";

import {
  DataPointApplicationFeaturesInvoicesDtosAddressDtoRead,
  DataPointApplicationFeaturesInvoicesDtosInvoiceDtoRead,
  serviceApi,
} from "../serviceApi";

type InvoicesState = {
  invoices?: DataPointApplicationFeaturesInvoicesDtosInvoiceDtoRead[];
  selectedActionCase?: {
    selectedCustomer?: {
      customerTaxNumber: string;
      name: string;
      address?: DataPointApplicationFeaturesInvoicesDtosAddressDtoRead;
    };
    action?: "factoring" | "payee";
    selectedInvoicesIds?: string[];
  };
};

const initialState: InvoicesState = {
  invoices: undefined,
  selectedActionCase: undefined,
};

const invoicesSlice = createSlice({
  name: "invoices",
  initialState,
  reducers: {
    setInvoices: (
      state,
      action: PayloadAction<
        DataPointApplicationFeaturesInvoicesDtosInvoiceDtoRead[]
      >
    ) => {
      state.invoices = action.payload;
    },
    resetInvoices: (state) => {
      state.invoices = undefined;
    },
    setSelectedCompanyByInvoiceName: (
      state,
      action: PayloadAction<{
        invoiceNumber: string;
        action: "factoring" | "payee";
      }>
    ) => {
      const invoice = state.invoices?.find(
        (invoice: DataPointApplicationFeaturesInvoicesDtosInvoiceDtoRead) =>
          invoice.invoiceNumber === action.payload.invoiceNumber
      );
      if (
        !state.selectedActionCase ||
        state.selectedActionCase?.selectedCustomer?.customerTaxNumber !==
          invoice?.customerTaxNumber ||
        state.selectedActionCase?.action !== action.payload.action
      ) {
        state.selectedActionCase = {};
      }
      state.selectedActionCase.selectedCustomer = {
        customerTaxNumber: invoice?.customerTaxNumber ?? "",
        name: invoice?.customerName ?? "",
        address: invoice?.customerAddress ?? undefined,
      };
      state.selectedActionCase.action = action.payload.action;
    },
    setSelectedInvoiceIds: (state, action: PayloadAction<string[]>) => {
      if (state.selectedActionCase) {
        state.selectedActionCase.selectedInvoicesIds = action.payload;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(serviceApi.endpoints.getInvoices.matchPending, () => {
        console.log("Loading invoices");
      })
      .addMatcher(
        serviceApi.endpoints.getInvoices.matchFulfilled,
        (state, action) => {
          state.invoices = action.payload;
        }
      );
  },
});

export const {
  setInvoices,
  resetInvoices,
  setSelectedCompanyByInvoiceName,
  setSelectedInvoiceIds,
} = invoicesSlice.actions;

export const useInvoices = (companyId?: string) => {
  const state = useSelector((state: RootState) => state.invoices);
  if (companyId) {
    const filteredInvoices = state.invoices?.filter(
      (i: DataPointApplicationFeaturesInvoicesDtosInvoiceDtoRead) =>
        i.companyId === companyId
    );
    return {
      invoices: filteredInvoices,
      selectedActionCase: {
        selectedCompany: state.selectedActionCase?.selectedCustomer,
        action: state.selectedActionCase?.action,
        selectedInvoicesIds: state.selectedActionCase?.selectedInvoicesIds,
      },
    };
  }

  return state;
};

export const useInvoicesByCompany = () => {
  const selectedCompany = useSelector(
    (state: RootState) => state.invoices.selectedActionCase?.selectedCustomer
  );

  const filteredInvoices = useSelector(
    (state: RootState) => state.invoices.invoices
  );

  return filteredInvoices && selectedCompany?.customerTaxNumber
    ? filteredInvoices.filter(
        (invoice) =>
          invoice.customerTaxNumber === selectedCompany.customerTaxNumber
      )
    : filteredInvoices?.filter(
        (invoice) =>
          invoice.customerName === selectedCompany?.name &&
          invoice.customerAddress?.city === selectedCompany?.address?.city &&
          invoice.customerAddress?.street ===
            selectedCompany?.address?.street &&
          invoice.customerAddress?.number === selectedCompany?.address?.number
      );
};

export const useSelectedInvoicesIds = () => {
  const selectedInvoiceIds = useSelector(
    (state: RootState) =>
      state.invoices.selectedActionCase?.selectedInvoicesIds ?? []
  );
  return selectedInvoiceIds;
};

export const useSelectedCompany = () => {
  const companyName = useSelector(
    (state: RootState) => state.invoices.selectedActionCase?.selectedCustomer
  );
  return companyName;
};

export default invoicesSlice.reducer;
