import {
  Overlay,
  P,
  Callout,
  Checkbox,
  ReactTable,
  Button,
} from "src/components";
import cn from "classnames";
import { useDispatch } from "react-redux";
import { AppDispatch } from "src/store/store";
import {
  setModalOpen,
  setModalClose,
  useModal,
} from "src/store/slices/modalSlice";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { Form as BaseForm } from "react-final-form";
import { useCallback, useEffect, useMemo, useState } from "react";
import { DataPointApplicationFeaturesInvoicesDtosInvoiceDto } from "src/store/serviceApi";
import {
  useSelectedCompany,
  useInvoicesByCompany,
  useSelectedInvoicesIds,
  setSelectedInvoiceIds,
} from "src/store/slices/invoicesSlice";
import * as yup from "yup";
import { useValidationSchema } from "src/hooks/useValidationSchema";
import {
  getDateLabelByDay,
  getDisplayValue,
  renderTestId,
} from "src/utils/HelperFunctions";
import { getAction } from "src/utils/transformers/invoiceDataTransformer";
import getSymbolFromCurrency from "currency-symbol-map";

type Data = {
  invoiceNumber: string;
  issueDate?: Date;
  paymentDate?: Date;
  customerName: string;
  netValue: number;
  currency: string;
  gueid: string;
};

const MODAL_ID = "invoices-selector-modal";

const InvoicesSelectorModal = () => {
  const invoices =
    useInvoicesByCompany()?.filter(
      (invoice: DataPointApplicationFeaturesInvoicesDtosInvoiceDto) =>
        getAction(
          new Date(invoice.paymentDate as string),
          invoice.factoringStatus,
          invoice.payeeStatus
        ) === "payee" && invoice.invoiceStatus !== 1
    ) ?? [];
  const selectedCompany = useSelectedCompany();
  const selectedInvoiceIds = useSelectedInvoicesIds() ?? [];
  const modal = useModal(MODAL_ID);
  const dispatch = useDispatch<AppDispatch>();
  const getSelectedRowIndeces = () => {
    const data = invoices
      .map(
        (
          invoice: DataPointApplicationFeaturesInvoicesDtosInvoiceDto,
          index: number
        ) => selectedInvoiceIds.includes(invoice.gueid ?? "") && `${index}`
      )
      .filter((result: string | false) => result) as string[];
    return data;
  };
  const [selectedRowIndeces, setSelectedRowIndeces] = useState(
    getSelectedRowIndeces()
  );
  useEffect(() => {
    setSelectedRowIndeces(getSelectedRowIndeces());
  }, [modal]);
  const toggleRowId = (rowId: string): void =>
    selectedRowIndeces.includes(rowId)
      ? setSelectedRowIndeces(
          selectedRowIndeces.filter((index: string) => index !== rowId)
        )
      : setSelectedRowIndeces([...selectedRowIndeces, rowId].sort());

  const invoiceModalValidationScheme = yup.object().shape({});
  const validate = useValidationSchema(invoiceModalValidationScheme);

  const columnHelper = createColumnHelper<Data>();

  const columns = [
    columnHelper.accessor("gueid", {
      header: () => "",
      meta: "!pr-0",
      cell: (row) => (
        <Checkbox
          name={row.row.original.gueid}
          variant="rounded"
          onChange={() => {
            toggleRowId(row.row.id);
          }}
        />
      ),
    }),
    columnHelper.accessor("invoiceNumber", {
      header: () => "Számlaszám",
      cell: (row) => (
        <P className="font-500 line-clamp-1">
          {row.getValue()}
          <span className="tooltip-contents">{row.getValue()}</span>
        </P>
      ),
    }),
    columnHelper.accessor("issueDate", {
      header: () => "Kelte",
      cell: (row) => <P>{getDateLabelByDay(row.getValue())}</P>,
    }),
    columnHelper.accessor("paymentDate", {
      header: () => "Fizetési határidő",
      cell: (row) => (
        <P className="font-700">{getDateLabelByDay(row.getValue())}</P>
      ),
    }),
    columnHelper.accessor("customerName", {
      header: () => "Partner",
      cell: () => (
        <P className="md:line-clamp-1">
          {selectedCompany?.name ? selectedCompany.name : "nincs név"}
          <span className="tooltip-contents">
            {selectedCompany?.name ? selectedCompany.name : "nincs név"}
          </span>
        </P>
      ),
    }),
    columnHelper.accessor("netValue", {
      header: () => <div className="text-right">Érték</div>,
      cell: (row) => (
        <div className="flex justify-end">
          <P className="text-right">
            <span className="font-700 mr-1">
              {getDisplayValue(row.getValue(), row.row.original.currency)}
            </span>
          </P>
          <P className="md:text-base text-sm font-500 opacity-60">
            {row.row.original.currency &&
              getSymbolFromCurrency(row.row.original.currency)}
          </P>
        </div>
      ),
    }),
  ] as ColumnDef<unknown, any>[];

  const createInitValues = useCallback(() => {
    const initValues: { [key: string]: boolean } = {};
    invoices.forEach(
      (i: DataPointApplicationFeaturesInvoicesDtosInvoiceDto) => {
        if (i.gueid) {
          initValues[i.gueid] = selectedInvoiceIds.includes(i.gueid);
        }
      }
    );
    return initValues;
  }, [selectedCompany, invoices, selectedInvoiceIds]);

  const initialValues = useMemo(
    () =>
      Object.freeze({
        ...createInitValues(),
      }),
    [selectedInvoiceIds]
  );

  const onSubmit = async (values: any) => {
    let results: string[] = [];
    for (const key in values) {
      if (
        values[key] &&
        invoices
          .map(
            (i: DataPointApplicationFeaturesInvoicesDtosInvoiceDto) => i.gueid
          )
          .includes(key)
      ) {
        results.push(key);
      }
    }
    dispatch(setSelectedInvoiceIds(results));
    dispatch(setModalClose(MODAL_ID));
    dispatch(setModalOpen("payee-packages-modal"));
  };

  return (
    <>
      <Overlay
        {...renderTestId(MODAL_ID)}
        id={MODAL_ID}
        title="Számlák kiválasztása"
        variant="Custom"
      >
        <div
          className={cn(
            "flex flex-col items-start gap-y-4 rounded-[20px]",
            "w-lg",
            "position"
          )}
        >
          <P className="font-400 text-16-22">
            Kérjük add meg, hogy milyen követeléseid vannak{" "}
            {selectedCompany?.name} felé!
          </P>

          <BaseForm
            onSubmit={onSubmit}
            initialValues={initialValues}
            validate={validate}
            render={({ handleSubmit, form, submitting, pristine, values }) => (
              <form
                className="flex flex-col gap-y-4 w-full"
                onSubmit={handleSubmit}
              >
                <div className="relative w-full overflow-auto">
                  <ReactTable
                    {...renderTestId("invoices-table")}
                    selectedRows={selectedRowIndeces}
                    variant="Collapsible"
                    data={invoices}
                    columns={columns}
                    sticky
                  />
                </div>
                <div className="sticky bottom-0 pt-4 flex justify-end gap-2 bg-gradient-to-t from-90% from-white">
                  <div className="flex flex-col gap-2">
                    <Callout
                      {...renderTestId("invoices-table-warning")}
                      variant="warning"
                    >
                      Fontos, hogy a kiválasztott számláknak egyetlen közös
                      jogviszonyhoz kell kapcsolódniuk. Erre a jogszabályi
                      megfelelés miatt van szükség.
                    </Callout>
                    <Callout variant="warning">
                      Kérjük, ne indíts faktorálási vagy behajtási folyamatot
                      olyan számlákra, amik már részben vagy teljesen ki vannak
                      egyenlítve. Ennek a felelősége téged terhel, mivel az
                      Adatpont nem rendelkezik a szükséges információkkal más
                      rendszerekben lévő tranzakciókról.
                    </Callout>
                  </div>
                  <div className="flex flex-col justify-end">
                    <Button
                      {...renderTestId("save-button")}
                      disabled={selectedRowIndeces.length === 0}
                      variant="Primary"
                      onClick={handleSubmit}
                    >
                      <P className="text-white !font-500 text-base">Mentés</P>
                    </Button>
                  </div>
                </div>
              </form>
            )}
          />
        </div>
      </Overlay>
    </>
  );
};

export default InvoicesSelectorModal;
