import { setModalData, setModalOpen } from "src/store/slices/modalSlice";
import { AppDispatch } from "src/store/store";
import { useDispatch } from "react-redux";
import { Icon, P } from "src/components";
import { useUser } from "src/store/slices/userSlice";
import { useCompany } from "src/store/slices/companySlice";
import cn from "classnames";
import {
  DataPointDomainCompaniesCompany,
  useGetMeQuery,
} from "src/store/serviceApi";

interface Props {
  companyItem: DataPointDomainCompaniesCompany;
}

const CompanyListItem: React.FC<Props> = ({ companyItem }) => {
  const dispatch = useDispatch<AppDispatch>();
  const { company, tenants } = useCompany();
  const { data: userData } = useGetMeQuery(
    {
      tenantId: company?.tenantId || "",
    },
    { refetchOnMountOrArgChange: true }
  );
  const handleOpenDeleteCompanyModal = () => {
    dispatch(setModalData(companyItem));
    dispatch(setModalOpen("delete-company-modal"));
  };

  return (
    <div
      className={cn(
        "flex flex-col items-start gap-4 self-stretch p-4 rounded-3xl border border-black/10",
        {
          "opacity-50 bg-gray-500/5": companyItem?.isDeleted,
        }
      )}
    >
      <div className="flex justify-between items-start self-stretch">
        <div className="items-center md:flex gap-2">
          <P className="!font-500">{companyItem.name ?? ""}</P>{" "}
          <P className="text-sm">
            {`(${
              tenants?.find((c) => c.id === company?.tenantId)?.name ?? ""
            } Kulcs-Ügyfél)`}
          </P>
        </div>
        {/* {userData?.role === TranslatedUserRoleValues.Administrator && !companyItem?.isDeleted && (
          <Icon
            icon="bin"
            className="text-[#ff8780] cursor-pointer"
            onClick={handleOpenDeleteCompanyModal}
          />
        )} */}
      </div>
      <div className="flex items-start gap-2">
        <P className="!font-500 !leading-[20px] !text-black/60">Adószám</P>
        <P className="">{companyItem.taxNumber}</P>
      </div>
    </div>
  );
};
export default CompanyListItem;
