import { Button } from "src/components";
import { useDispatch } from "react-redux";
import { AppDispatch } from "src/store/store";
import { setModalOpen } from "src/store/slices/modalSlice";
import InvoicesSelectorModal from "./InvoicesSelectorModal";
import { useEffect } from "react";
import {
  setInvoices,
} from "src/store/slices/invoicesSlice";

const InvoicesSelectorDemo = () => {
  const dispatch = useDispatch<AppDispatch>();
  // const testSelectedCompany = {
  //   id: "selectedCo",
  //   name: "Selected Company",
  // };
  const testData = [
    {
      id: "invoice_id_1",
      companyId: "selectedCo1",
      invoiceId: 1234,
      invoiceNumber: "NK-1234",
      customerName: "Tuco Salamanca",
      customerTaxNumber: "TAX-MEX1",
      address: "Tijuana Rue de Cartel 6",
      date: new Date().toISOString(),
      fulfillmentDate: new Date().toISOString(),
      paymentDate: new Date().toISOString(),
      state: "not payed",
      netValue: 1000000,
      vatValue: 1270000,
      grossValue: 1350000,
      currency: "HUF",
      currencyRate: undefined,
      rowVersion: undefined,
    },
    {
      id: "invoice_id_2",
      companyId: "selectedCo1",
      invoiceId: 1235,
      invoiceNumber: "NK-1235",
      customerName: "Tuco Salamanca",
      customerTaxNumber: "TAX-MEX1",
      address: "Tijuana Bienvenido cl 5",
      date: new Date().toISOString(),
      fulfillmentDate: new Date().toISOString(),
      paymentDate: new Date().toISOString(),
      state: "not payed",
      netValue: 1000000,
      vatValue: 1270000,
      grossValue: 1350000,
      currency: "HUF",
      currencyRate: undefined,
      rowVersion: undefined,
    },
    {
      id: "invoice_id_3",
      companyId: "selectedCo1",
      invoiceId: 1236,
      invoiceNumber: "NK-1236",
      customerName: "Tuco Salamanca",
      customerTaxNumber: "TAX-MEX2",
      address: "Tijuana Bajo California calle 6",
      date: new Date().toISOString(),
      fulfillmentDate: new Date().toISOString(),
      paymentDate: new Date().toISOString(),
      state: "not payed",
      netValue: 1000000,
      vatValue: 1270000,
      grossValue: 1350000,
      currency: "HUF",
      currencyRate: undefined,
      rowVersion: undefined,
    },
    {
      id: "invoice_id_4",
      companyId: "selectedCo2",
      invoiceId: 1237,
      invoiceNumber: "NK-1237",
      customerName: "Lalo Salamanca",
      customerTaxNumber: "TAX-MEX1",
      address: "Tijuana Rue de Cartel 6",
      date: new Date().toISOString(),
      fulfillmentDate: new Date().toISOString(),
      paymentDate: new Date().toISOString(),
      state: "not payed",
      netValue: 1000000,
      vatValue: 1270000,
      grossValue: 1350000,
      currency: "HUF",
      currencyRate: undefined,
      rowVersion: undefined,
    },
    {
      id: "invoice_id_5",
      companyId: "selectedCo2",
      invoiceId: 1238,
      invoiceNumber: "NK-1238",
      customerName: "Lalo Salamanca",
      customerTaxNumber: "TAX-MEX1",
      address: "Tijuana Bienvenido cl 5",
      date: new Date().toISOString(),
      fulfillmentDate: new Date().toISOString(),
      paymentDate: new Date().toISOString(),
      state: "not payed",
      netValue: 1000000,
      vatValue: 1270000,
      grossValue: 1350000,
      currency: "HUF",
      currencyRate: undefined,
      rowVersion: undefined,
    },
    {
      id: "invoice_id_6",
      companyId: "selectedCo2",
      invoiceId: 1239,
      invoiceNumber: "NK-1239",
      customerName: "Lalo Salamanca",
      customerTaxNumber: "TAX-MEX2",
      address: "Tijuana Bajo California calle 6",
      date: new Date().toISOString(),
      fulfillmentDate: new Date().toISOString(),
      paymentDate: new Date().toISOString(),
      state: "not payed",
      netValue: 1000000,
      vatValue: 1270000,
      grossValue: 1350000,
      currency: "HUF",
      currencyRate: undefined,
      rowVersion: undefined,
    },
    {
      id: "invoice_id_7",
      companyId: "selectedCo3",
      invoiceId: 1240,
      invoiceNumber: "NK-1240",
      customerName: "Hector Salamanca",
      customerTaxNumber: "TAX-MEX1",
      address: "Tijuana Rue de Cartel 6",
      date: new Date().toISOString(),
      fulfillmentDate: new Date().toISOString(),
      paymentDate: new Date().toISOString(),
      state: "not payed",
      netValue: 1000000,
      vatValue: 1270000,
      grossValue: 1350000,
      currency: "HUF",
      currencyRate: undefined,
      rowVersion: undefined,
    },
    {
      id: "invoice_id_8",
      companyId: "selectedCo3",
      invoiceId: 1241,
      invoiceNumber: "NK-1241",
      customerName: "Hector Salamanca",
      customerTaxNumber: "TAX-MEX1",
      address: "Tijuana Bienvenido cl 5",
      date: new Date().toISOString(),
      fulfillmentDate: new Date().toISOString(),
      paymentDate: new Date().toISOString(),
      state: "not payed",
      netValue: 1000000,
      vatValue: 1270000,
      grossValue: 1350000,
      currency: "HUF",
      currencyRate: undefined,
      rowVersion: undefined,
    },
    {
      id: "invoice_id_9",
      companyId: "selectedCo4",
      invoiceId: 1242,
      invoiceNumber: "NK-1242",
      customerName: "Salamanca Twins",
      customerTaxNumber: "TAX-MEX2",
      address: "Tijuana Bajo California calle 6",
      date: new Date().toISOString(),
      fulfillmentDate: new Date().toISOString(),
      paymentDate: new Date().toISOString(),
      state: "not payed",
      netValue: 1000000,
      vatValue: 1270000,
      grossValue: 1350000,
      currency: "HUF",
      currencyRate: undefined,
      rowVersion: undefined,
    },
  ];

  useEffect(() => {
    dispatch(setInvoices(testData));
  }, []);

  return (
    <>
      <Button onClick={() => dispatch(setModalOpen("invoices-selector-modal"))}>
        Payee számlák választása
      </Button>
      <InvoicesSelectorModal />
    </>
  );
};

export default InvoicesSelectorDemo;
