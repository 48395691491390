import slugify from "slugify";

export function isDate(date: string) {
  return (
    new Date(date).toLocaleDateString() !== "Invalid Date" &&
    !isNaN(new Date(date).getDate())
  );
}

export const getDateLabel = (
  date: Date | string | null | undefined
): string => {
  if (!date) return "---";

  const today = new Date();
  const _date = new Date(date);
  if (
    _date.getDate() === today.getDate() &&
    _date.getMonth() === today.getMonth() &&
    _date.getFullYear() === today.getFullYear()
  ) {
    const hours = String(_date.getHours()).padStart(2, "0");
    const minutes = String(_date.getMinutes()).padStart(2, "0");
    return `${hours}:${minutes}`;
  } else {
    const year = _date.getFullYear();
    const month = String(_date.getMonth() + 1).padStart(2, "0");
    const day = String(_date.getDate()).padStart(2, "0");
    const hours = String(_date.getHours()).padStart(2, "0");
    const minutes = String(_date.getMinutes()).padStart(2, "0");
    return `${year}.${month}.${day}. ${hours}:${minutes}`;
  }
};

export const getDateLabelByDay = (date: Date | undefined): string => {
  if (!date) {
    return "-";
  }

  const _date = new Date(date);
  return `${_date.getFullYear()}.${String(_date.getMonth() + 1).padStart(
    2,
    "0"
  )}.${String(_date.getDate()).padStart(2, "0")}.`;
};

export const getTimeDeltaByDay = (
  date1?: Date | string | null,
  date2?: Date
): number => {
  if (!date1 || !date2) {
    return 0;
  }

  const _MS_PER_DAY = 1000 * 60 * 60 * 24;
  const delta = new Date(date1).getTime() - new Date(date2).getTime();
  return Math.ceil(delta / _MS_PER_DAY);
};

export const getDateRanges = (filter: 0 | 1 | 2 | 3 = 1, isTwoYearsMedianToday = false): { start: string, till: string } => {
  const DAY_MS = 86400000;
  const today = new Date();
  today.setHours(0);
  today.setMinutes(0);
  today.setSeconds(0);

  let startDate = today;
  let tillDate = new Date(today.getTime() + 365 * DAY_MS);

  if (filter === 1) {
    startDate = new Date(today.getTime() - 30 * DAY_MS);
    tillDate = new Date(today.getTime() - 1 * DAY_MS);
  }
  if (filter === 2) {
    startDate = new Date(today.getTime() - 60 * DAY_MS);
    tillDate = new Date(today.getTime() - 31 * DAY_MS);
  }
  if (filter === 3) {
    startDate = new Date(today.getTime() - 366 * DAY_MS);
    tillDate = new Date(today.getTime() - 61 * DAY_MS);
  }
  if (isTwoYearsMedianToday) {
    startDate = new Date(today.getTime() - 366 * DAY_MS);
    tillDate = new Date(today.getTime() + 366 * DAY_MS);
  }
  return { start: startDate.toISOString().split("T")[0], till: tillDate.toISOString().split("T")[0] };
}

export const getDateTwoYearsRanges = (): { start: string, till: string } => {
  const DAY_MS = 86400000;
  const today = new Date();
  return {
    start: new Date(today.getTime() - 366 * DAY_MS).toISOString().split("T")[0],
    till: new Date(today.getTime() + 366 * DAY_MS).toISOString().split("T")[0]
  }
}


export const dateFormatter = new Intl.DateTimeFormat("hu-HU", {
  year: "numeric",
  month: "short",
  day: "2-digit",
});

export const currencyFormatter = new Intl.NumberFormat("hu-HU", {
  style: "decimal",
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
  useGrouping: true,
});

export const formatSimpleNumbers = (number: any) => {
  if (!number || number === Infinity) return "";

  const absNumber = Math.abs(parseInt(number));

  if (absNumber >= 1000 && absNumber < 1000000) {
    const formattedNumber = (absNumber / 1000).toFixed(0);
    return number < 0 ? `-${formattedNumber} K` : `${formattedNumber} K`;
  } else if (absNumber >= 1000000) {
    const formattedNumber = (absNumber / 1000000).toFixed(0);
    return number < 0 ? `-${formattedNumber} M` : `${formattedNumber} M`;
  }

  return number.toString();
};

export const getDisplayValue = (
  value?: string | number,
  currency?: string,
  roundToBillion?: boolean
) => {
  if (value !== 0 && !value) return "";

  if (typeof value === "string") {
    value = parseFloat(value.replace(",", "."));
  }

  const billion = 1e9;
  if (roundToBillion && typeof value === "number" && value >= billion) {
    const milliards = Math.round((value / billion) * 1000) / 1000;
    return `${milliards.toLocaleString("hu-HU", {
      minimumFractionDigits: 3,
      maximumFractionDigits: 3,
    })} mrd`;
  }

  let digits = 2;

  if (
    currency?.toLowerCase() === "huf" ||
    currency?.toLowerCase() === "ft" ||
    value % 1 === 0
  ) {
    digits = 0;
  }

  return value.toLocaleString("hu-HU", {
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
  });
};

export const getDisplayValueWithCurrency = (
  value?: string | number,
  currency = "HUF",
  minimumFractionDigits = 0,
  maximumFractionDigits?: number
) => {
  if (value !== 0 && !value) return "";

  if (typeof value === "string") {
    value = parseFloat(value.replace(",", "."));
  }

  return value.toLocaleString("hu-HU", {
    style: "decimal",
    minimumFractionDigits: currency === "EUR" ? 2 : minimumFractionDigits,
    maximumFractionDigits:
      maximumFractionDigits !== undefined ? maximumFractionDigits : 3,
  });
};

export const renderTestId = (testid: string) => {
  if (process.env.REACT_APP_TEST_ENABLED === "Y") {
    return {
      "data-testid": slugify(testid, {
        replacement: "-",
        lower: true,
        trim: true,
      }),
    };
  }
  return;
};

export const isModalDataEmpty = (data: any): boolean => {
  return (
    !data ||
    data.every((d: any) => d.headers?.length === 0 || d.values?.length === 0)
  );
};
