import { P, Tag, Button, Checkbox, Submit, Overlay, A } from "src/components";
import { useState } from "react";
import PayeePackageTabs, {
  DataElement,
} from "../PayeePackageTabs/PayeePackageTabs";
import { useValidationSchema } from "src/hooks/useValidationSchema";
import { startPayeeValidationScheme } from "src/validation/schemes";
import { Form as BaseForm } from "react-final-form";
import {
  useSelectedCompany,
  useSelectedInvoicesIds,
} from "src/store/slices/invoicesSlice";
import { Links } from "src/utils/constants/Links";
import { useCompany } from "src/store/slices/companySlice";
import { renderTestId } from "src/utils/HelperFunctions";
import {
  DataPointApplicationFeaturesIntegrationsPayeePayeeLevels,
  useAddCaseMutation,
} from "src/store/serviceApi";

const initialValues = Object.freeze({
  tickbox1: false,
  tickbox2: false,
});

const MODAL_ID = "payee-packages-modal";

const PayeeModal = () => {
  const selectedCompany = useSelectedCompany();
  const company = useCompany();
  const invoiceIds = useSelectedInvoicesIds();
  const [activeTab, setActiveTab] = useState<"Soft" | "Medium" | "Hard">(
    "Soft"
  );
  const tabDataModuleClaims = [
    { label: "Soft", content: "" },
    { label: "Medium", content: "" },
    { label: "Hard", content: "" },
  ];
  const links = Links.Payee;
  const validate = useValidationSchema(startPayeeValidationScheme);
  const [addCase] = useAddCaseMutation();

  const handleActiveTabChange = (s: "Soft" | "Medium" | "Hard") => {
    setActiveTab(s);
  };

  const handlePayeeLevel = (
    level: "Soft" | "Medium" | "Hard"
  ): DataPointApplicationFeaturesIntegrationsPayeePayeeLevels => level;

  const openInNewTab = (url: string) => {
    window.open(url, "_blank", "noreferrer");
  };

  const handleSave = async () => {
    if (company.company?.id) {
      let res;
      await addCase({
        companyId: company.company?.id,
        body: {
          level: handlePayeeLevel(activeTab as "Soft" | "Medium" | "Hard"),
          invoices: invoiceIds,
        },
      })
        .unwrap()
        .then((response) => {
          if (response.url) {
            openInNewTab(response.url);
            res = response;
          }
        })
        .catch((error) => {
          console.log("ADD PAYEE CASE ERROR", error);
        });

      return res;
    }
  };

  return (
    <Overlay
      {...renderTestId(MODAL_ID)}
      title="Követeléskezelés indítása"
      id={MODAL_ID}
      variant="Custom"
      subTitle={
        <div className="flex justify-start">
          <Tag>Ügyindítás 3 perc alatt</Tag>
        </div>
      }
    >
      <BaseForm
        validate={validate}
        initialValues={initialValues}
        onSubmit={handleSave}
      >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <div className="max-h-[80vh] w-full overflow-auto flex flex-col gap-4">
              <div className="flex items-start gap-1.5 self-stretch">
                <P className="">
                  <strong>Adós neve, aki tartozik:</strong>
                </P>
                <P className="">{selectedCompany?.name}</P>
              </div>
              <div className="flex">
                <span className="text-black/70 text-base leading-[22px]">
                  Követeléskezelési partnerünk, a Payee segítségével
                  villámgyorsan indíthatod el a behajtási folyamatot. Helyetted
                  kommunikál a hatóságokkal, és élőben értesít a státuszokról a
                  fiókodban és emailben.
                  <A
                    {...renderTestId("module-select-link")}
                    href={links.ModuleInfo}
                    className="font-500 text-sm ml-4"
                    variant="underline"
                  >
                    Melyik modult válasszam?
                  </A>
                </span>
              </div>
              <PayeePackageTabs
                data={tabDataModuleClaims as DataElement[]}
                activeTab={activeTab}
                handleActiveTabChange={handleActiveTabChange}
              />

              <div className="flex px-3 w-full justify-start items-center gap-[10px] self-stretch">
                <Checkbox
                  {...renderTestId("confirm-payee-terms-check")}
                  variant="grey"
                  name="tickbox1"
                />
                <span className="text-black/70 ">
                  Elolvastam és elfogadom a Payee, azaz a Legal Labs Kft{" "}
                  <A href={links.TermsAndConditions}>
                    Általános Szolgáltatási Feltételeit
                  </A>{" "}
                  és az{" "}
                  <A href={links.PrivacyPolicy}>Adatkezelési tájékoztatóját.</A>
                </span>
              </div>
              <div className="flex h-12 px-3 justify-start items-center gap-[10px] self-stretch">
                <Checkbox
                  {...renderTestId("confirm-payee-privacy-check")}
                  variant="grey"
                  name="tickbox2"
                />
                <span className="text-black/70 ">
                  Elfogadom, hogy a gombra kattintva létrejön a Payee profilom,
                  és partnereim adatait a követeléskezelési ügy indításához a{" "}
                  {selectedCompany?.name} átadja a Payee részére.
                </span>
              </div>
              <div className="flex justify-end gap-4 self-stretch items-end">
                <Button
                  {...renderTestId("more-button")}
                  onClick={() => openInNewTab(Links.Payee.MoreInfo)}
                  variant="Secondary"
                  type="button"
                >
                  <P className="text-primary !font-500 text-base">
                    Bővebb információ és útmutató
                  </P>
                </Button>
                <div className="flex flex-col justify-end items-end gap-4">
                  <A
                    href={links.AfterRegistration}
                    className="font-500 text-sm "
                    variant="underline"
                  >
                    Mi történik az indítás után?
                  </A>
                  <Submit variant="Primary" onClick={handleSubmit}>
                    <P className="text-white !font-500 text-base">
                      Tovább az adatmegadásra
                    </P>
                  </Submit>
                </div>
              </div>
            </div>
          </form>
        )}
      </BaseForm>
    </Overlay>
  );
};
export default PayeeModal;
