import {
  Data as DataByCompany,
  InvoiceData,
} from "src/components/organisms/AccountReceivableManagement/AccountsReceivableManagementTable/AccountsReceivableManagementTableByCo";
import { Data as DataByAllInvoices } from "src/components/organisms/AccountReceivableManagement/AccountsReceivableManagementTable/AccountsReceivableManagementTableByInvoices";
import { getTimeDeltaByDay } from "../HelperFunctions";
import { DataPointApplicationFeaturesInvoicesDtosInvoiceDtoRead } from "src/store/serviceApi";

// Function to help filter customers and invoices
const _handleFilter = (filter: number, status: string): boolean =>
  filter === 0 ||
  (filter === 1 && status === "factoring") ||
  (filter === 2 && status === "payee");

const _getStatus = (
  factoringStatus?: string | null,
  payeeStatus?: string | null
): string => {
  let result = "";
  if (factoringStatus && +factoringStatus <= 5 && +factoringStatus > 0) {
    result = "Finanszírozott";
  }
  if (factoringStatus && +factoringStatus > 5) {
    result = "Térült";
  }
  if (payeeStatus && +payeeStatus <= 5 && +payeeStatus > 0) {
    result = "Folyamatban";
  }
  if (payeeStatus && +payeeStatus > 5) {
    result = "Lezárult";
  }
  return result;
};

export const getAction = (
  date?: Date | null | string,
  factoringStatus?: string | null,
  payeeStatus?: string | null
): "factoring" | "payee" | "" => {
  if (!date) {
    return "";
  }
  if (
    getTimeDeltaByDay(date, new Date()) >= 0 &&
    factoringStatus === "0" &&
    process.env.REACT_APP_FACTORING_ENABLED === "Y"
  ) {
    return "factoring";
  }
  if (
    getTimeDeltaByDay(date, new Date()) < 0 &&
    payeeStatus === "0" &&
    process.env.REACT_APP_PAYEE_ENABLED === "Y"
  ) {
    return "payee";
  }
  return "";
};

const _displayExpiracyDate = (delta: number): string => {
  // TODO later maybe this will be used
  // if (delta === 0) {
  //   return "Ma";
  // } else
  if (delta >= 0) {
    return `${delta} nap`;
  } else {
    return `Lejárt ${Math.abs(delta)} napja`;
  }
};

// Function to transform original data into desired structure
export const transformInvoicesByCo = (
  invoices: any[],
  filter: number
): DataByCompany[] => {
  const data = invoices
    ?.filter(
      (invoice: DataPointApplicationFeaturesInvoicesDtosInvoiceDtoRead) =>
        invoice.invoiceStatus !== 2
    )
    .reduce((acc: DataByCompany[], item) => {
      // Find existing customer in accumulator

      let existingCustomer = acc.find(
        (customer) => customer.customerName === item.customerName
      );

      // If customer does not exist, create new one
      if (!existingCustomer) {
        existingCustomer = {
          customerName: item.customerName,
          sum: 0,
          collapsible: {
            customerTaxNumber: item.customerTaxNumber,
            address: item.customerAddress,
            invoiceData: [],
          },
          hasContract: false,
        };
        acc.push(existingCustomer);
      }

      const action =
        item.invoiceStatus !== 1
          ? getAction(item.paymentDate, item.factoringStatus, item.payeeStatus)
          : "";
      // Add invoice data to the customer
      if (_handleFilter(filter, action)) {
        existingCustomer.collapsible.invoiceData.push({
          invoiceNumber: item.invoiceNumber,
          date:
            typeof item.issueDate === "string"
              ? new Date(item.issueDate)
              : item.issueDate,
          paymentDate: item.paymentDate,
          expiracy: _displayExpiracyDate(
            getTimeDeltaByDay(item.paymentDate, new Date())
          ),
          status: _getStatus(item.factoringStatus, item.payeeStatus),
          netValue: item.netValue,
          isPartiallyFinanced: item.invoiceStatus === 1 ?? false,
          action: action,
          currency: item.currency ?? "",
          currencyRate: item.currencyRate ?? 1,
        });
      }
      const valueWithCurrency =
        (item.netValue as number) * (item.currencyRate as number);

      // Update net total for the customer
      existingCustomer.sum += valueWithCurrency as number;

      return acc;
    }, []);

  for (let dataElement of data) {
    dataElement.hasContract = dataElement.collapsible.invoiceData.some(
      (d: InvoiceData) => d.status === "Finanszírozott" || d.status === "Térült"
    );
  }
  // Filtering and removing all the empty existing costumers
  return data.filter(
    (data: DataByCompany) => data.collapsible.invoiceData.length > 0
  );
};

export const transformInvoicesByAllInvoices = (
  invoices: any[],
  filter: number,
  latestOnTop: boolean
): DataByAllInvoices[] => {
  const data = invoices
    .filter(
      (invoice: DataPointApplicationFeaturesInvoicesDtosInvoiceDtoRead) =>
        invoice.invoiceStatus !== 2
    )
    .map((i: DataPointApplicationFeaturesInvoicesDtosInvoiceDtoRead) => ({
      invoiceNumber: i.invoiceNumber ?? undefined,
      customerName: i.customerName ?? undefined,
      issueDate: i.issueDate ? new Date(i.issueDate) : undefined,
      paymentDate: i.paymentDate ? new Date(i.paymentDate) : undefined,
      expiracy: _displayExpiracyDate(
        getTimeDeltaByDay(i.paymentDate, new Date())
      ),
      status: _getStatus(i.factoringStatus, i.payeeStatus),
      netValue: i.netValue,
      currency: i.currency ?? "",
      collapsible: {
        customerTaxNumber: i.customerTaxNumber ?? undefined,
        address: i.customerAddress ?? undefined,
        action:
          i.invoiceStatus !== 1
            ? getAction(i.paymentDate, i.factoringStatus, i.payeeStatus)
            : "",
        isPartiallyFinanced: i.invoiceStatus === 1,
      },
    }))
    .sort(function (a, b) {
      return latestOnTop
        ? new Date(b.issueDate ?? 0).valueOf() -
            new Date(a.issueDate ?? 0).valueOf()
        : new Date(a.issueDate ?? 0).valueOf() -
            new Date(b.issueDate ?? 0).valueOf();
    });

  if (data?.length === 0) return [];

  return data;
};
