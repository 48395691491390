import type { ColumnDef, Row } from "@tanstack/react-table";
import type { AppDispatch } from "src/store/store";
import { useEffect, useState } from "react";
import { createColumnHelper } from "@tanstack/react-table";
import { useDispatch } from "react-redux";
import {
  Button,
  CollapsibleReactTable,
  FactoringModal,
  Icon,
  InvoicesSelectorModal,
  P,
  PayeeModal,
  Tag,
  Loader,
  ReactTable,
} from "src/components";
import { useCompany } from "src/store/slices/companySlice";
import { setSelectedCompanyByInvoiceName } from "src/store/slices/invoicesSlice";
import {
  currencyFormatter,
  getDateLabelByDay,
  getDateTwoYearsRanges,
  getDisplayValue,
} from "src/utils/HelperFunctions";
import { transformInvoicesByCo } from "src/utils/transformers/invoiceDataTransformer";
import { setModalOpen } from "src/store/slices/modalSlice";
import {
  DataPointApplicationFeaturesCompaniesDtosAddressDto,
  useGetInvoicesQuery,
} from "src/store/serviceApi";
import getSymbolFromCurrency from "currency-symbol-map";

export type InvoiceData = {
  invoiceNumber?: string;
  date?: Date;
  paymentDate?: Date;
  expiracy?: string;
  status?: string;
  netValue?: number;
  isPartiallyFinanced?: boolean;
  action?: string;
  currency: string;
  currencyRate: any;
};

type ClientData = {
  customerTaxNumber?: string;
  address?: DataPointApplicationFeaturesCompaniesDtosAddressDto;
  invoiceData: InvoiceData[];
};

export type Data = {
  customerName?: string;
  sum: number;
  hasContract?: boolean;
  collapsible: ClientData;
};

interface Props {
  filter: number;
}

const columnHelper = createColumnHelper<Data | InvoiceData>();

const clientColumns = [
  columnHelper.accessor("customerName", {
    header: () => "Vevő neve",
    size: 80,
    cell: (row) => (
      <div className="flex flex-row gap-x-2 items-center">
        <P className="text-sm md:text-base line-clamp-1 text-black/60">
          {row.getValue()}
        </P>
        {/* {(row.row.original as Data).hasContract ? (
          <Tag color="green">
            <div className="flex flex-row gap-x-1 items-center">
              <Icon icon="checkmark" size={16} />
              Faktorszerződéssel rendelkezik
            </div>
          </Tag>
        ) : (
          <Tag color="orange">
            <div className="flex flex-row items-center gap-x-1">
              <Icon icon="info" size={16} />
              Faktorszerződéssel nem rendelkezik
            </div>
          </Tag>
        )} */}
      </div>
    ),
  }),
  columnHelper.accessor("sum", {
    header: () => <span className="flex justify-end">Összeg</span>,
    size: 20,
    cell: (row) => (
      <P className="text-sm md:text-base line-clamp-1 text-right !font-bold">
        {/* TODO: need currency prop in Data */}
        {currencyFormatter.format(row.getValue())}{" "}
        <span className="font-normal">Ft</span>
      </P>
    ),
  }),
] as ColumnDef<unknown, any>[];

const getInvoiceDataColumns = (
  actionHandler: (invoiceNumber: string, action: "factoring" | "payee") => void
) =>
  [
    columnHelper.accessor("invoiceNumber", {
      header: () => "Számla sorszáma",
      cell: (row) => (
        <P className="text-sm md:text-base line-clamp-1 break-all">
          {row.getValue()}
          <span className="tooltip-contents">{row.getValue()}</span>
        </P>
      ),
    }),
    columnHelper.accessor("date", {
      header: () => "Kelte",
      cell: (row) => (
        <P className="text-sm md:text-base line-clamp-1 break-all">
          {getDateLabelByDay(row.getValue())}
          <span className="tooltip-contents">
            {getDateLabelByDay(row.getValue())}
          </span>
        </P>
      ),
    }),
    columnHelper.accessor("paymentDate", {
      header: () => "Fiz. hat.",
      cell: (row) => (
        <P className="text-sm md:text-base line-clamp-1 break-all">
          {getDateLabelByDay(row.getValue())}
          <span className="tooltip-contents">
            {getDateLabelByDay(row.getValue())}
          </span>
        </P>
      ),
    }),
    columnHelper.accessor("expiracy", {
      header: () => "Lejárat",
      cell: (row) => (
        <P className="text-sm md:text-base line-clamp-1 break-all">
          {row.getValue()}
          <span className="tooltip-contents">{row.getValue()}</span>
        </P>
      ),
    }),
    columnHelper.accessor("status", {
      header: () => "Státusz",
      cell: (row) => (
        <P className="text-sm md:text-base line-clamp-1 min-h-10">
          {row.getValue().length > 0 && (
            <Tag
              color={
                row.getValue() === "Finanszírozott" ||
                row.getValue() === "Térült"
                  ? "orange"
                  : "green"
              }
            >
              {row.getValue()}
            </Tag>
          )}
          {(row.row.original as InvoiceData).isPartiallyFinanced && (
            <Tag color="primary">Részkiegyenlített</Tag>
          )}
        </P>
      ),
    }),
    columnHelper.accessor("netValue", {
      header: () => "Összeg",
      cell: (row) => (
        <span className="flex gap-2 pl-2 py-1">
          <P className="text-sm md:text-base line-clamp-1 font-bold">
            {getDisplayValue(
              row.getValue(),
              (row.row.original as InvoiceData).currency
            )}
          </P>{" "}
          <P className="md:text-base text-sm font-500 opacity-60">
            {(row.row.original as InvoiceData).currency &&
              getSymbolFromCurrency((row.row.original as InvoiceData).currency)}
          </P>
        </span>
      ),
    }),
    columnHelper.accessor("action", {
      header: () => (
        <span className="flex flex-row justify-end">
          {(process.env.REACT_APP_FACTORING_ENABLED === "Y" ||
            process.env.REACT_APP_FACTORING_ENABLED === "Y") &&
            "Akció"}
        </span>
      ),
      cell: (row) => (
        <span className="flex flex-row justify-end">
          {row.getValue() !== "" &&
            !(row.row.original as InvoiceData).isPartiallyFinanced && (
              <Button
                variant="Default"
                onClick={() => {
                  actionHandler(
                    (row.cell.row.original as InvoiceData).invoiceNumber ?? "",
                    row.getValue()
                  );
                }}
              >
                <Icon
                  icon={row.getValue() === "factoring" ? "flexibill" : "payee"}
                  variant="Container"
                  size={24}
                  className="text-primary"
                />
              </Button>
            )}
        </span>
      ),
    }),
  ] as ColumnDef<unknown, any>[];

const CollapsibleRow = ({ row }: { row: Row<Data> }) => {
  const dispatch = useDispatch<AppDispatch>();
  const { customerTaxNumber, address, invoiceData } = row.original.collapsible;

  const actionHandler = (
    invoiceNumber: string,
    action: "factoring" | "payee",
    isPartiallyFinanced?: boolean
  ) => {
    dispatch(setSelectedCompanyByInvoiceName({ invoiceNumber, action }));
    dispatch(
      setModalOpen(
        action === "factoring"
          ? "factoring-start-modal"
          : "invoices-selector-modal"
      )
    );
  };

  return (
    <div className="flex flex-col">
      <div className="flex flex-row self-start gap-x-5 p-4 m-4 bg-primary/[0.05] border border-primary/10 rounded-[10px]">
        <div>
          <h6 className="uppercase text-xs text-black/50">Adószám</h6>
          <P className="text-sm md:text-base line-clamp-1">
            {customerTaxNumber ?? "-"}
          </P>
        </div>
        <div>
          <h6 className="uppercase text-xs text-black/50">Cím</h6>
          <P className="text-sm md:text-base line-clamp-1">
            {address?.zip} {address?.city}, {address?.street}{" "}
            {address?.publicDomain} {address?.number} {address?.building}{" "}
            {address?.staircase} {address?.floor} {address?.door}
          </P>
        </div>
      </div>
      <div className="w-full overflow-auto">
        <ReactTable
          variant="Collapsible"
          data={invoiceData}
          columns={getInvoiceDataColumns(actionHandler)}
        />
      </div>
    </div>
  );
};

const AccountsReceivableManagementTableByCo: React.FC<Props> = ({
  filter: filterStatus,
  ...rest
}) => {
  const { company } = useCompany();
  const [transformedInvoices, setTransformedInvoices] = useState<Data[] | null>(
    null
  );

  const {
    data: invoices,
    isLoading,
    isFetching,
  } = useGetInvoicesQuery({
    companyId: company?.id ?? "",
    dueDateFrom: getDateTwoYearsRanges().start,
    dueDateTo: getDateTwoYearsRanges().till,
  });

  useEffect(() => {
    if (invoices) {
      const _transformedInvoices = transformInvoicesByCo(
        invoices,
        filterStatus
      );
      setTransformedInvoices(_transformedInvoices);
    }
  }, [filterStatus]);

  useEffect(() => {
    if (invoices && !transformedInvoices) {
      const _transformedInvoices = transformInvoicesByCo(
        invoices,
        filterStatus
      );
      setTransformedInvoices(_transformedInvoices);
    }
  }, [invoices]);

  return (
    <div>
      {(isLoading || isFetching) && <Loader variant={"blue"} />}
      {transformedInvoices && (
        <>
          <div className="w-full overflow-auto">
            <CollapsibleReactTable
              variant="sticky"
              data={transformedInvoices}
              columns={clientColumns}
              renderCollapsibleRow={CollapsibleRow}
              {...rest}
            />
          </div>
          <InvoicesSelectorModal />
          <FactoringModal />
          <PayeeModal />
        </>
      )}
    </div>
  );
};

export default AccountsReceivableManagementTableByCo;
